function setPage(state, page, ids, pageInfo) {
  return {
    ...state,
    pages: {
      ...state.pages,
      [page]: ids,
    },
    pageInfo,
    newShort: null,
    newShortError: null,
  };
}

function addRecords(state, records) {
  return {
    ...state,
    records: { ...state.records, ...records },
  };
}

function addRecord(state, id, record) {
  return {
    ...state,
    records: {
      ...state.records,
      [id]: record,
    },
  };
}

const initialState = {
  pages: {},
  records: {},
};

export default function urlShortsReducer(state, action) {
  switch (action.type) {
    case 'URL_SHORTS_LOADING':
      return setPage(state, action.query.page, null);

    case 'URL_SHORTS_LOADED': {
      const ids = action.urlShorts.map(({ id }) => id);
      const records = action.urlShorts.reduce((acc, o) => ({ ...acc, [o.id]: o }), {});
      const stateWithPage = setPage(state, action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, records);
    }

    case 'URL_SHORT_LOADED':
      return addRecord(state, action.id, action.urlShort);

    case 'URL_SHORT_REVOKED': {
      const record = {
        ...Object.values(state.records).find(({ short }) => short === action.short),
        state: action.state,
      };

      return addRecord(state, record.id, record);
    }

    case 'URL_SHORT_CREATEING':
      return { ...state, newShort: null, newShortError: null };

    case 'URL_SHORT_CREATED':
      return { ...state, newShort: action.newShort };

    case 'URL_SHORT_CREATION_FAILED':
      return { ...state, newShortError: action.error };

    default:
      return state || initialState;
  }
}
