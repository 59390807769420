import { ServiceTypesActions } from './serviceTypes.const';

const initialState = {
  isLoading: false,
  records: [],
};

export default function serviceTypesReducer(state = initialState, action) {
  switch (action.type) {
    case ServiceTypesActions.StartLoading:
      return { isLoading: true };

    case ServiceTypesActions.StopLoading:
      return { isLoading: false, records: action.records };

    default:
      return state;
  }
}
