import { setPage, addRecords } from './data';

const initialState = {
  fees: {
    pages: {},
    records: {},
  },
  statements: {
    pages: {},
    records: {},
  },
};

export default function affiliateReducer(state, action) {
  switch (action.type) {
    case 'AFFILIATE_FEES_LOADING':
      return setPage(state, 'fees', action.query.page, null);

    case 'AFFILIATE_FEES_LOADED': {
      const ids = action.fees.map(fee => fee.id);
      const records = action.fees.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'fees',
        action.query.page,
        ids,
        action.pageInfo
      );

      return addRecords(stateWithPage, 'fees', records);
    }

    case 'AFFILIATE_STATEMENTS_LOADING':
      return setPage(state, 'statements', action.query.page, null);

    case 'AFFILIATE_STATEMENTS_LOADED': {
      const ids = action.statements.map(statement => statement.id);
      const records = action.statements.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'statements',
        action.query.page,
        ids,
        action.pageInfo
      );

      return addRecords(stateWithPage, 'statements', records);
    }

    case 'AFFILIATE_STATEMENT_CREATED':
      return initialState;

    default:
      return state || initialState;
  }
}
