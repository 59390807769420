const initialState = {
  // for per-entity listing
  entityId: null,
  data: null,

  byOffer: {},

  // for general listing (not entity specific)
  pages: {},
  records: {},

  // for page showing single detailed distribution channel
  singleRecords: {},
};

export default function (state, action) {
  switch (action.type) {
    case 'DISTRIBUTION_CHANNELS_BY_ENTITY_LOADING':
      return {
        ...state,
        entityId: action.entityId,
        data: null,
      };

    case 'DISTRIBUTION_CHANNELS_BY_ENTITY_LOADED':
      if (state.entityId !== action.entityId) {
        // request took longer than subsequent request
        return state;
      }

      return {
        ...state,
        data: action.response,
      };

    case 'DISTRIBUTION_CHANNELS_BY_OFFER_LOADING':
      return {
        ...state,
        byOffer: { ...state.byOffer, [action.offerId]: null },
      };

    case 'DISTRIBUTION_CHANNELS_BY_OFFER_LOADED': {
      const records = action.response.reduce((r, dc) => ({ ...r, [dc.id]: dc }), {});
      const ids = action.response.map(({ id }) => id);

      return {
        ...state,
        byOffer: { ...state.byOffer, [action.offerId]: ids },
        records: { ...state.records, ...records },
      };
    }

    case 'DISTRIBUTION_CHANNELS_LOADING':
      return {
        ...state,
        pages: { ...state.pages, [action.query.page]: null },
      };

    case 'DISTRIBUTION_CHANNELS_LOADED': {
      const page = action.query.page;
      const ids = action.response.map(dc => dc.id);
      const records = action.response.reduce((r, dc) => ({ ...r, [dc.id]: dc }), {});

      return {
        ...state,
        pages: { ...state.pages, [page]: ids },
        records: { ...state.records, ...records },
        pageInfo: action.pageInfo,
      };
    }

    case 'DISTRIBUTION_CHANNEL_LOADING':

    case 'DISTRIBUTION_CHANNEL_LOADED':
      return {
        ...state,
        singleRecords: {
          ...state.singleRecords,
          [action.id]: action.response || null,
        },
      };

    default:
      return state || initialState;
  }
}
