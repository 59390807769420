import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import activations from 'resources/activations/activations.reducer';
import analytics from 'resources/analytics/analytics.reducer';
import serviceTypes from 'resources/serviceTypes/serviceTypes.reducer';
import urlShortsReducer from 'resources/url-shorts/url-shorts.reducer';

import affiliate from './affiliate';
import assignments from './assignments';
import auth from './auth';
import authorizationAgreements from './authorizationAgreements';
import autocomplete from './autocomplete';
import companyEvents from './companyEvents';
import crypt from './crypt';
import data from './data';
import dealers from './dealers';
import distributionChannels from './distributionChannels';
import documents from './documents';
import email from './email';
import flash from './flash';
import importReducer from './import';
import offerEvents from './offerEvents';
import persons from './persons';
import placeList from './placeList';
import placeListGroups from './placeListGroups';
import properties from './properties';
import triggers from './triggers';
import users from './users';
import zipcodeGroups from './zipcodeGroups';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    data,
    flash,
    autocomplete,
    email,
    users,
    distributionChannels,
    affiliate,
    documents,
    import: importReducer,
    properties,
    urlShorts: urlShortsReducer,
    crypt,
    assignments,
    offerEvents,
    companyEvents,
    triggers,
    dealers,
    activations,
    zipcodegroups: zipcodeGroups,
    agreements: authorizationAgreements,
    placeList,
    serviceTypes,
    persons,
    analytics,
    placeListGroups,
  });
