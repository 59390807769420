import { putIn } from '../utils';

const initialState = {
  keys: {
    createErrors: null,
    records: {},
  },
  data: {
    createErrors: null,
    records: {},
  },
  delegations: {
    byCompany: {},
  },
  serverKey: null,
};

export default function cryptReducer(state, action) {
  switch (action.type) {
    case 'CRYPT_KEY_LOADING':
      return {
        ...state,
        keys: { ...state.keys, records: { ...state.keys.records, [action.id]: null } },
      };

    case 'CRYPT_KEY_LOADED':
      return {
        ...state,
        keys: {
          ...state.keys,
          records: { ...state.keys.records, [action.id]: action.cryptKey },
        },
      };

    case 'CRYPT_KEY_CREATE_FAILED':
      return { ...state, keys: { ...state.keys, createErrors: action.errors } };

    case 'CRYPT_KEY_CREATE_SUCCEEDED':
      return { ...state, keys: { ...state.keys, createErrors: null } };

    case 'CRYPT_DATA_CREATE_FAILED':
      return { ...state, data: { ...state.data, createErrors: action.errors } };

    case 'CRYPT_DATA_CREATE_SUCCEEDED':
      return { ...state, data: { ...state.data, createErrors: null } };

    case 'CRYPT_LOAD_SERVER_KEY':
      return { ...state, serverKey: action.key };

    case 'CRYPT_DATA_LOADING':
      return {
        ...state,
        data: { ...state.data, records: { ...state.data.records, [action.id]: null } },
      };

    case 'CRYPT_DATA_LOADED':
      return {
        ...state,
        data: {
          ...state.data,
          records: { ...state.data.records, [action.id]: action.data },
        },
      };

    case 'CRYPT_DELEGATIONS_LOADING':
      return putIn(state, ['delegations', 'byCompany', action.companyId], null);

    case 'CRYPT_DELEGATIONS_LOADED':
      return putIn(
        state,
        ['delegations', 'byCompany', action.companyId],
        action.delegations
      );

    default:
      return state || initialState;
  }
}
