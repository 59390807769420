import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';

import Flash from './components/Flash';
import routes, { redirects } from './routes';

import './assets/src/scss/styles.scss';

function NotFound() {
  return <h2 className="text-center">404 Page Not Found</h2>;
}

const getLoadableRoute = route => {
  return <Route {...route} exact={route.exact !== false} key={route.path} />;
};

const getRedirectRoute = route => {
  return (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact !== false}
      render={() => <Redirect to={route.to} />}
    />
  );
};

const App = () => {
  return (
    <div className="App">
      <Flash />
      <div className="Content">
        <Switch>
          {redirects.map(route => getRedirectRoute(route))}

          {routes.map(route => getLoadableRoute(route))}

          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(App);
