import { addRecords, setFailedState, setLoadingState, setPage } from './data';

const initialState = {
  pages: {},
  records: {},
  pageInfo: {},
  isLoading: false,
  isFailed: false,
  schemas: {
    isLoading: false,
    isFailed: false,
  },
  details: {
    isLoading: false,
    isFailed: false,
    pages: {},
    records: {},
    pageInfo: {},
  },
  movements: {
    pages: {},
    records: {},
    pageInfo: {},
  },
};

export default function placeListReducer(state, action) {
  switch (action.type) {
    case 'PERSONS_LOADING':
      return {
        ...state,
        pages: { ...state.pages, [action.query.page]: null },
        isLoading: true,
        isFailed: false,
      };

    case 'PERSONS_LOADED': {
      const { entities, query, pageInfo } = action;
      const ids = entities.map(item => item.id);
      const records = entities.reduce((r, o) => ({ ...r, [o.id]: o }), {});

      return {
        ...state,
        records: { ...state.records, ...records },
        pages: { ...state.pages, [query.page]: ids },
        isLoading: false,
        isFailed: false,
        pageInfo,
      };
    }

    case 'PERSONS_FAILED':
      return {
        ...state,
        isLoading: false,
        isFailed: true,
      };

    case 'PERSON_DETAILS_LOADING':
      return setLoadingState(state, 'details');

    case 'PERSON_DETAILS_FAILED':
      return setFailedState(state, 'details');

    case 'PERSON_DETAILS_LOADED':
      return {
        ...state,
        details: {
          ...state.details,
          [action.id]: action.result,
          isLoading: false,
          isFailed: false,
        },
      };

    case 'PERSON_ITEM_LOADED':
      return {
        ...state,
        records: {
          ...state.records,
          [action.id]: action.result,
        },
      };

    case 'PERSON_SCHEMA_LOADING':
      return setLoadingState(state, 'schemas');

    case 'PERSON_SCHEMA_FAILED':
      return setFailedState(state, 'schemas');

    case 'PERSON_SCHEMA_LOADED':
      return {
        ...state,
        schemas: {
          ...state.schemas,
          [action.id]: action.result,
          isLoading: false,
          isFailed: false,
        },
      };

    case 'PERSON_MOVEMENTS_LOADING':
      return setPage(state, 'movements', action.query.page, null, undefined, true);

    case 'PERSON_MOVEMENTS_LOADED': {
      const ids = action.entities.map(({ id }) => id);
      const records = action.entities.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'movements',
        action.query.page,
        ids,
        action.pageInfo,
        false
      );

      return addRecords(stateWithPage, 'movements', records);
    }

    case 'PERSON_MOVEMENTS_FAILED':
      return {
        ...state,
        movements: {
          ...state.movements,
          isLoading: false,
          isFailed: true,
        },
      };

    default:
      return state || initialState;
  }
}
