import React from 'react';

import { Base64 } from 'js-base64';
import parsePhoneNumber from 'libphonenumber-js';
import qs from 'qs';

export function formatMoney({ value, unit }) {
  if (unit !== 'USD') {
    throw new Error(`Expected money to be in USD, but was given in ${unit} instead`);
  }
  const valueString = value.toString();
  const digitsAfterPoint =
    valueString.indexOf('.') === -1 ? 0 : valueString.split('.')[1].length;

  return `$${value.toFixed(Math.min(Math.max(2, digitsAfterPoint), 4))}`;
}

export function formatTimeWithUnit({ value, unit }) {
  return `${value} ${unit}${value > 1 ? 's' : ''}`;
}

export function formatFee({ amount, recurring, event }) {
  const snippets = [];

  snippets.push(formatMoney(amount));
  if (recurring) {
    const { unit, value } = recurring;

    snippets.push(
      `/ ${value === 1 ? '' : `${value} `}${value === 1 ? unit : `${unit}s`}`
    );
  }
  if (event) {
    snippets.push(`on ${event}`);
  }

  return snippets.join(' ');
}

export function formatFeeCondition({ operator, value, unit }) {
  return `${operator} ${value} ${unit}`;
}

export function formatDate(value) {
  if (typeof value === 'string') {
    return formatDate(new Date(Date.parse(value)));
  }
  if (value instanceof Date) {
    const y = value.getFullYear();
    const m = (value.getMonth() + 1).toString();
    const d = value.getDate().toString();

    return `${y}-${m.padStart(2, '0')}-${d.padStart(2, '0')}`;
  }
  if (value === null) {
    return '(no value)';
  }

  return `Invalid date: ${value}`;
}

export function formatTime(value) {
  if (typeof value === 'string') {
    return formatTime(new Date(Date.parse(value)));
  }
  if (value instanceof Date) {
    const h = value.getHours().toString();
    const m = value.getMinutes().toString();
    const s = value.getSeconds().toString();

    return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
  }

  return '-';
}

export function formatDateTime(value) {
  if (typeof value === 'string') {
    return formatDateTime(new Date(Date.parse(value)));
  }

  return `${formatDate(value)} ${formatTime(value)}`;
}

export function formatLanguage(lang) {
  return (
    {
      en: 'English',
      es: 'Spanish',
    }[lang] || lang
  );
}

export const formatPhoneNumber = value => {
  if (!value) {
    return '';
  }

  const res = parsePhoneNumber(value, { defaultCountry: 'US' });

  if (!res) {
    return value;
  }

  if (res.country === 'US') {
    return res.formatNational();
  }

  return res.formatInternational();
};

export const getHumanReadableFileSize = byteSize => {
  if (byteSize <= 1024) return `${byteSize} Bytes`;
  if (byteSize <= 1024 ** 2) return `${(byteSize / 1024).toFixed(1)} KB`;
  if (byteSize <= 1024 ** 3) return `${(byteSize / 1024 ** 2).toFixed(1)} MB`;

  return `${((byteSize / 1024) ** 3).toFixed(1)} GB`;
};

export const formatPhoneNumberToSting = value => {
  if (!value) {
    return '';
  }

  const res = parsePhoneNumber(value, { defaultCountry: 'US' });

  if (!res) {
    return value;
  }

  return res.nationalNumber;
};

export function parseQuery(props) {
  const {
    location: { search },
  } = props;

  return search ? qs.parse(search.replace(/^\?/, '')) : {};
}

export function optionsForSelect(options) {
  return options.map(([value, label]) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));
}

export function optionLabel(item, options) {
  const option = options.find(([k]) => k === item);

  return option ? option[1] : item;
}

export function capitalizeWords(string) {
  return string
    .split(' ')
    .filter(s => s.length > 0)
    .map(s => s[0].toUpperCase() + s.slice(1))
    .join(' ');
}

export function stateOptionsFromTransitions(transitions) {
  const allStates = Object.keys(
    transitions.reduce(
      (o, { from, to }) =>
        [...from, to].reduce((x, state) => ({ ...x, [state]: true }), o),
      {}
    )
  );

  return allStates.map(state => [state, capitalizeWords(state)]);
}

export function toBase64(string) {
  return Base64.encode(string);
}

function filterNavItems(items, accessScope) {
  return items
    .map(item =>
      item.children
        ? { ...item, children: filterNavItems(item.children, accessScope) }
        : item
    )
    .filter(({ perm, children }) =>
      children ? children.length > 0 : accessScope.includes(perm)
    );
}

export function filterNav(nav, accessScope) {
  return {
    ...nav,
    items: filterNavItems(nav.items, accessScope),
  };
}

export function blobToText(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', e => resolve(e.target.result));
    reader.addEventListener('error', reject);
    reader.readAsText(blob);
  });
}

export function isTextFile(contentType) {
  return ['yaml', 'json', 'text'].find(pattern => contentType.includes(pattern)) || false;
}

export function filterNulls(object, nullable = []) {
  return Object.entries(object).reduce(
    (o, [k, v]) => (v === null && !nullable.includes(k) ? o : { ...o, [k]: v }),
    {}
  );
}

export function stripPrefix(prefix, string) {
  if (string.slice(0, prefix.length) === prefix) {
    return string.slice(prefix.length);
  }

  return string;
}

export function putIn(object, keys, value) {
  const [key] = keys;

  if (keys.length === 1) {
    return { ...object, [key]: value };
  }

  return { ...object, [key]: putIn(object[key] || {}, keys.slice(1), value) };
}

export function extractDateFromISO(timeString) {
  const match = timeString && timeString.match(/^(\d{4}-\d{2}-\d{2})/);

  return match ? match[1] : <em>(Invalid time string: {JSON.stringify(timeString)})</em>;
}

function hexString(buffer) {
  const byteArray = new Uint8Array(buffer);

  const hexCodes = [...byteArray].map(value => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, '0');

    return paddedHexCode;
  });

  return hexCodes.join('');
}

export async function fileHashDigest(file) {
  const content = await new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', () => reject(new Error('Failed to read from file')));
    reader.readAsArrayBuffer(file);
  });
  const hashDigest = await crypto.subtle.digest('SHA-256', content);

  return hexString(hashDigest);
}

export function moveItem(list, index, direction) {
  switch (direction) {
    case 'up':
      return [
        ...list.slice(0, index - 1),
        list[index],
        list[index - 1],
        ...list.slice(index + 1),
      ];

    case 'down':
      return [
        ...list.slice(0, index),
        list[index + 1],
        list[index],
        ...list.slice(index + 2),
      ];

    default:
      throw new Error(`Unknown move direction: ${direction}`);
  }
}

export function getQueryFromLocationSearch(search) {
  return search ? qs.parse(search.replace(/^\?/, '')) : {};
}

export function createKeysObject(array) {
  return array.reduce((acc, item) => {
    acc[item] = item;

    return acc;
  }, {});
}

export const isTrue = value => value === 'true';

export const FORMATS = {
  /** 23:59 */
  TIME: 'HH:mm',
  /** 10-27-2022 */
  DATE: 'MM/DD/YYYY',
  /** 10-27-2022 23:59 */
  DATE_TIME: 'MM/DD/YYYY HH:mm',
};
