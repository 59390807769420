const initialState = {
  // for per-entity listing
  entityId: null,
  data: [],

  // for page showing single detailed distribution channel
  singleRecords: {},
};

export default function (state, action) {
  switch (action.type) {
    case 'AGREEMENTS_BY_ENTITY_LOADING':
      return {
        ...state,
        entityId: action.entityId,
        data: [],
      };

    case 'AGREEMENTS_BY_ENTITY_LOADED':
      if (state.entityId !== action.entityId) {
        // request took longer than subsequent request
        return state;
      }

      return {
        ...state,
        data: action.agreements || [],
      };

    case 'AGREEMENT_DELETED':
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== action.id),
        singleRecords: {
          ...state.singleRecords,
          [action.id]: null,
        },
      };

    case 'AGREEMENT_LOADING':
      return {
        ...state,
        singleRecords: {
          ...state.singleRecords,
          [action.id]: null,
        },
      };

    case 'AGREEMENT_LOADED':
      let isUpdated = false;

      const newData = state.data.map(agreement => {
        if (action.id === agreement.id) {
          isUpdated = true;

          return {
            ...agreement,
            ...action.agreement,
          };
        }

        return agreement;
      });

      if (!isUpdated) {
        newData.push(action.agreement);
      }

      return {
        ...state,
        data: newData,
        singleRecords: {
          ...state.singleRecords,
          [action.id]: action.agreement,
        },
      };

    default:
      return state || initialState;
  }
}
