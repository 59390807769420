const initialState = {
  pages: {},
  records: {},
  pageInfo: null,
};

const setPage = (state, page, ids, pageInfo) => ({
  ...state,
  pages: {
    ...state.pages,
    [page]: ids,
  },
  pageInfo,
});

const addRecords = (state, records) => ({
  ...state,
  records: {
    ...state.records,
    ...records,
  },
});

export default function zipcodeGroupsReducer(state, action) {
  switch (action.type) {
    case 'ZIPCODE_GROUPS_LOADING':
      return setPage(state, action.query.page, null);

    case 'ZIPCODE_GROUPS_LOADED': {
      const ids = action.zipcodegroups.map(group => group.id);
      const records = action.zipcodegroups.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, action.query.page, ids, action.pageInfo);

      return addRecords(stateWithPage, records);
    }

    case 'ZIPCODE_GROUP_LOADED':
      return {
        ...state,
        records: { ...state.records, [action.id]: action.zipcodegroup },
      };

    case 'ZIPCODE_GROUP_REMOVED': {
      const newState = { ...state, records: { ...state.records } };

      delete newState.records[action.id];
      newState.pages[newState.pageInfo.page] = newState.pages[
        newState.pageInfo.page
      ].filter(value => value !== action.id);

      return newState;
    }

    default:
      return state || initialState;
  }
}
