import { setAuthInfo, renewSession, warnAboutLogout } from 'actions/auth';
import store from 'resources/store';

const { idecoAuthInfo } = localStorage;

if (idecoAuthInfo) {
  let parsedAuthInfo;

  try {
    parsedAuthInfo = JSON.parse(idecoAuthInfo);
  } catch (e) {}
  if (parsedAuthInfo) {
    store.dispatch(setAuthInfo(parsedAuthInfo));
  }
}

store.subscribe(() => {
  const { auth } = store.getState();

  if (auth && auth.token) {
    localStorage.idecoAuthInfo = JSON.stringify(auth);
  } else {
    delete localStorage.idecoAuthInfo;
  }
});

const safeParseJSON = (json, fallback = null) => {
  try {
    return JSON.parse(json);
  } catch (error) {}

  return fallback;
};

const getMostFreshFreshAuthData = () => {
  const { auth } = store.getState();
  const idecoAuthInfo = safeParseJSON(localStorage.idecoAuthInfo);

  if (idecoAuthInfo && auth) {
    const storageTokenExpirationTime = Date.parse(idecoAuthInfo.expiresAt);
    const storeTokenExpirationTime = Date.parse(auth.expiresAt);

    if (storageTokenExpirationTime > storeTokenExpirationTime) return idecoAuthInfo;

    return auth;
  }

  return idecoAuthInfo || auth;
};

export function handleExpiringToken() {
  const storedAuthInfo = store.getState().auth;
  const freshAuthData = getMostFreshFreshAuthData();

  if (!freshAuthData || !freshAuthData.token) return;

  const expiresAtTime = Date.parse(freshAuthData.expiresAt);
  const lastActivityTime = Date.parse(freshAuthData.lastActivity);
  const now = new Date().getTime();

  const isTokenShouldBeRefreshed = expiresAtTime - 600000 < now;
  const isTokenExpired = now > expiresAtTime;

  if (isTokenExpired) {
    console.log('[handleExpiringToken] Token is expired');

    store.dispatch(
      setAuthInfo({ ...freshAuthData, error: 'Token expired', errorSource: null })
    );

    return;
  }

  if (isTokenShouldBeRefreshed) {
    console.log(
      `[handleExpiringToken] Token will expire in less than 10 minutes (${freshAuthData.expiresAt})`
    );
    // less than 10 minutes until token expires...
    if (lastActivityTime + 600000 >= now) {
      // ... and it  has been at most 10 minutes since the user was last active:
      // -> attempt to refresh the token before it expires.
      console.log(
        `[handleExpiringToken] User was active within last 10 minutes, renewing session (${freshAuthData.lastActivity})`
      );
      store.dispatch(renewSession());
    } else if (expiresAtTime - 120000 < now && !freshAuthData.showLogoutWarning) {
      console.log(
        `[handleExpiringToken] User was not active within last 10 minutes, showing logout warning (${freshAuthData.lastActivity})`
      );
      store.dispatch(warnAboutLogout());
    }
  }

  if (!isTokenShouldBeRefreshed && storedAuthInfo !== freshAuthData) {
    console.log('[handleExpiringToken] Token replaced');
    store.dispatch(setAuthInfo({ ...freshAuthData, error: null, errorSource: null }));
  }
}

setInterval(handleExpiringToken, 30000);
setTimeout(handleExpiringToken);
