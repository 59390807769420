import { addRecords, setFailedState, setLoadingState, setPage } from './data';

const initialState = {
  pages: {},
  records: {},
  pageInfo: {},
  places: {
    pages: {},
    records: {},
    schemas: {
      isLoading: false,
      isFailed: false,
    },
    pageInfo: {},
    details: {
      isLoading: false,
      isFailed: false,
    },
  },
  schemas: {
    isLoading: false,
    isFailed: false,
  },
  details: {
    pages: {},
    records: {},
    pageInfo: {},
    isLoading: false,
    isFailed: false,
  },
  detailInfo: {},
};

export default function placeListReducer(state, action) {
  switch (action.type) {
    case 'PLACE_LIST_ITEM_LOADED': {
      return {
        ...state,
        records: {
          ...state.records,
          [action.id]: action.placeListItem,
        },
      };
    }

    case 'PLACE_LIST_LOADING':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.query.page]: null,
        },
      };

    case 'PLACE_LIST_LOADED': {
      const { placeList, query, pageInfo } = action;
      const ids = placeList.map(property => property.id);
      const records = placeList.reduce((r, o) => ({ ...r, [o.id]: o }), {});

      return {
        ...state,
        records: {
          ...state.records,
          ...records,
        },
        pages: {
          ...state.pages,
          [query.page]: ids,
        },
        pageInfo,
      };
    }

    case 'PLACE_LOADED':
      return addRecords(state, 'places', { [action.id]: action.place });

    case 'PLACES_LOADING':
      return setPage(state, 'places', action.query.page, null);

    case 'PLACES_LOADED': {
      const { places, query, pageInfo } = action;
      const ids = places.map(place => place.id);
      const records = places.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(state, 'places', query.page, ids, pageInfo);

      return addRecords(stateWithPage, 'places', records);
    }

    case 'DETAILS_LOADING':
      return setPage(state, 'details', action.query.page, null);

    case 'DETAILS_LOADED': {
      const { details, query, pageInfo } = action;
      const ids = details.map(i => i.correlation_id);
      const records = details.reduce((r, o) => ({ ...r, [o.correlation_id]: o }), {});
      const stateWithPage = setPage(state, 'details', query.page, ids, pageInfo);

      return addRecords(stateWithPage, 'details', records);
    }

    case 'DETAIL_LOADED':
      return {
        ...state,
        detailInfo: {
          ...state.detailInfo,
          [action.correlationId]: action.detail,
        },
      };

    case 'DETAIL_ADDRESS_VALIDATION_LOADED':
      return {
        ...state,
        detailInfo: {
          ...state.detailInfo,
          [action.correlationId]: {
            ...state.detailInfo[action.correlationId],
            validation: action.result,
          },
        },
      };

    case 'PLACE_DETAILS_LOADING':
      return { ...state, places: setLoadingState(state.places, 'details') };

    case 'PLACE_DETAILS_FAILED':
      return { ...state, places: setFailedState(state.places, 'details') };

    case 'PLACE_DETAILS_LOADED':
      return {
        ...state,
        places: {
          ...state.places,
          details: {
            ...state.places.details,
            [action.placeId]: action.result,
            isLoading: false,
            isFailed: false,
          },
        },
      };

    case 'PLACE_SCHEMA_LOADING':
      return { ...state, places: setLoadingState(state.places, 'schemas') };

    case 'PLACE_SCHEMA_FAILED':
      return { ...state, places: setFailedState(state.places, 'schemas') };

    case 'PLACE_SCHEMA_LOADED':
      return {
        ...state,
        places: {
          ...state.places,
          schemas: {
            ...state.places.schemas,
            [action.id]: action.result,
            isLoading: false,
            isFailed: false,
          },
        },
      };

    case 'PLACE_LIST_DETAILS_LOADING':
      return { ...state, ...setLoadingState(state, 'details') };

    case 'PLACE_LIST_DETAILS_FAILED':
      return { ...state, ...setFailedState(state, 'details') };

    case 'PLACE_LIST_DETAILS_LOADED':
      return {
        ...state,
        details: {
          ...state.details,
          [action.placeListId]: action.result,
          isLoading: false,
          isFailed: false,
        },
      };

    case 'PLACE_LIST_SCHEMA_LOADING':
      return { ...state, ...setLoadingState(state, 'schemas') };

    case 'PLACE_LIST_SCHEMA_FAILED':
      return { ...state, ...setFailedState(state, 'schemas') };

    case 'PLACE_LIST_SCHEMA_LOADED':
      return {
        ...state,
        schemas: {
          ...state.schemas,
          [action.id]: action.result,
          isLoading: false,
          isFailed: false,
        },
      };

    case 'PLACE_ASSIGNED_TO_PLACE_LIST': {
      const place = state.places.records[action.id];
      const updatedPlace = {
        ...place,
        place_lists: [...place.place_lists, { id: action.placeListId }],
      };

      return addRecords(state, 'places', { [action.id]: updatedPlace });
    }

    case 'PLACE_REMOVED_FROM_PLACE_LIST': {
      const place = state.places.records[action.id];
      const updatedPlace = {
        ...place,
        place_lists: place.place_lists.filter(i => i.id !== action.placeListId),
      };

      return addRecords(state, 'places', { [action.id]: updatedPlace });
    }

    default:
      return state || initialState;
  }
}
