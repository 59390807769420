import * as flash from '../actions/flash';

export default function errorCatcher() {
  function handleError(e) {
    if (e.status === 401) {
      if (e.message.match(/expired/)) {
        delete localStorage.idecoAuthInfo;
        document.location.reload();
      } else {
        return dispatch => {
          dispatch({ type: 'UNAUTHORIZED', message: e.message });
          dispatch(flash.error(`Request failed: ${e.message}`));
        };
      }
    } else if (e.show) {
      console.error(e);

      return flash.error(`${e.name}: ${e.message}`);
    } else {
      throw e;
    }
  }

  function handleFunction(next, fn) {
    next(async dispatch => {
      try {
        return fn(action => handle(dispatch, action));
      } catch (e) {
        return handle(next, handleError(e));
      }
    });
  }

  function handlePromise(next, promise) {
    return promise.then(
      action => {
        if (typeof action !== 'undefined') {
          return next(action);
        }

        return console.warn('Ignoring undefined result of promise.');
      },
      error => {
        next(handleError(error));
      }
    );
  }

  function handle(next, action) {
    if (typeof action === 'function') {
      return handleFunction(next, action);
    }
    if (action && typeof action.then === 'function') {
      return handlePromise(next, action);
    }
    if (typeof action !== 'undefined') {
      return next(action);
    }
  }

  return next => action => handle(next, action);
}
