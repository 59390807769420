import * as constants from './activations.constants';

const initialState = {
  page: 1,
  perPage: 25,
  total: 0,
  activations: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.action.ACTIVATIONS_SHOW_LOADER:
      return { ...state, isLoading: true };

    case constants.action.ACTIVATIONS_HIDE_LOADER:
      return { ...state, isLoading: false };

    case constants.action.ACTIVATIONS_SET_PAGE:
      return { ...state, page: action.payload };

    case constants.action.ACTIVATIONS_SET_TOTAL:
      return { ...state, total: action.payload };

    case constants.action.ACTIVATIONS_FETCHED:
      return { ...state, activations: action.payload };

    default:
      return state;
  }
}
