import { IMPORT_ACTIONS } from '../actions/const';

import { setPage, addRecords } from './data';

const initialState = {
  sourceSettings: {
    isLoading: false,
    records: {},
    filter: { search: '' },
    sort: {},
  },
  sources: {
    pages: {},
    records: {},
  },
  credentials: {
    pages: {},
    records: {},
    pageInfo: {},
    isLoading: false,
    isFailed: false,
  },
  imports: {
    pages: {},
    records: {},
  },
};

export default function importReducer(state, action) {
  switch (action.type) {
    case IMPORT_ACTIONS.IMPORT_CREDENTIAL_LOADED:
      return addRecords(state, 'credentials', { [action.id]: action.data });

    case IMPORT_ACTIONS.IMPORT_CREDENTIALS_LOADING:
      return setPage(state, 'credentials', action.query.page, null, undefined, true);

    case IMPORT_ACTIONS.IMPORT_CREDENTIALS_LOADED: {
      const ids = action.data.map(({ id }) => id);
      const records = action.data.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'credentials',
        action.query.page,
        ids,
        action.pageInfo,
        false
      );

      return addRecords(stateWithPage, 'credentials', records);
    }

    case IMPORT_ACTIONS.IMPORT_CREDENTIALS_FAILED:
      return {
        ...state,
        credentials: {
          ...state.sources,
          isLoading: false,
          isFailed: true,
        },
      };

    case IMPORT_ACTIONS.RESET_SETTINGS_QUERY: {
      return {
        ...state,
        sourceSettings: { ...state.sourceSettings, sort: {}, filter: {} },
      };
    }

    case IMPORT_ACTIONS.SET_SETTINGS_QUERY: {
      return { ...state, sourceSettings: { ...state.sourceSettings, ...action.payload } };
    }

    case IMPORT_ACTIONS.SETTINGS_LOADING: {
      return {
        ...state,
        sourceSettings: { ...state.sourceSettings, isLoading: action.payload },
      };
    }

    case IMPORT_ACTIONS.SETTINGS_LOADED: {
      return {
        ...state,
        sourceSettings: {
          ...state.sourceSettings,
          records: { [action.payload.source.id]: action.payload.settings },
        },
      };
    }

    case 'IMPORT_SOURCES_LOADING':
      return setPage(state, 'sources', action.query.page, null, undefined, true);

    case 'IMPORT_SOURCES_LOADED': {
      const ids = action.importSources.map(({ id }) => id);
      const records = action.importSources.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'sources',
        action.query.page,
        ids,
        action.pageInfo,
        false
      );

      return addRecords(stateWithPage, 'sources', records);
    }

    case 'IMPORT_SOURCES_FAILED':
      return {
        ...state,
        sources: {
          ...state.sources,
          isLoading: false,
          isFailed: true,
        },
      };

    case 'IMPORT_SOURCE_LOADED':
      return addRecords(state, 'sources', { [action.id]: action.importSource });

    case 'IMPORTS_LOADING':
      return setPage(state, 'imports', action.query.page, null, undefined, true);

    case 'IMPORTS_LOADED': {
      const ids = action.imports.map(({ id }) => id);
      const records = action.imports.reduce((r, o) => ({ ...r, [o.id]: o }), {});
      const stateWithPage = setPage(
        state,
        'imports',
        action.query.page,
        ids,
        action.pageInfo,
        false
      );

      return addRecords(stateWithPage, 'imports', records);
    }

    case 'IMPORT_LOADED':
      return addRecords(state, 'imports', { [action.id]: action.imp });

    case 'IMPORT_SOURCE_CREATE_FAILED':
      return {
        ...state,
        sources: {
          ...state.sources,
          createErrors: action.errors,
        },
      };

    case 'IMPORT_SOURCE_CREATE_SUCCEEDED':
      return {
        ...state,
        sources: {
          ...state.sources,
          createErrors: null,
        },
      };

    default:
      return state || initialState;
  }
}
